import React from 'react'; // eslint-disable-line
import { useState, useEffect } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './checkout/checkout';
import './assets/css/styles.css'
import './assets/css/footer-pages-styles.css'
import ThankYou from './checkout/thankyou';
import { ProductsPage } from './home/productsPage';
import Privacy from './privacy-cookies/privacy';
import PrivacyCalifornia from './privacy-cookies/california';
import TermsAndConditions from './terms-of-use/termsAndConditions';
import http from './services/http';
import NotFound from './notfound/notFound';

const App = () => {

  const [config, setConfig] = useState();
  const getConfig = async () => {
    try {
      const baseURL = window.location.origin;
      const result = await http.get(baseURL + '/js/config.json');
      return result.data;
    }
    catch (e) {
      console.log(e)
    }
  }

  //TODO: setup sentry configuration

  useEffect(() => {
    (async () => {
      const configData = await getConfig();
      setConfig(configData);
      sessionStorage.setItem('config', JSON.stringify(configData));
      http.loadBaseUrl();
    })();
  }, []);

  return (
    <div className="App">
      {config ?
        <>
          <Header />
          <BrowserRouter>
            <Routes>
              <Route path="/checkout/:trackType/:trackPage" element={<Checkout config={config} />} />
              <Route path="/checkout/thankyou" element={<ThankYou config={config}/>} />
              <Route path="/privacy" element={<Privacy config={config} />} />
              <Route path="/privacy/california" element={<PrivacyCalifornia config={config} />} />
              <Route path="/terms-of-use" element={<TermsAndConditions config={config} />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/" element={<ProductsPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </>
        : null}
    </div>
  );
}

export default App;