import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

const  Privacy = (props: any) => {
  const pageUrl = props.config.assetsUrl + '/contents/privacy.html';
  const [htmlBody, setHtmlBody] = useState('');
  
  // read the contents from source and sanitize it
  React.useEffect(() => {		
    fetch(pageUrl)
    .then(response => response.text())
    .then(text => {
      setHtmlBody(DOMPurify.sanitize(text));
  });
	}, []); // eslint-disable-line

   useEffect(() => {
    const updateAccordion = () => {
      const accordions = document.querySelectorAll('.accordion-header');

      accordions.forEach(header => {
        header.addEventListener('click', () => {
          // Toggle active class on the header
          header.classList.toggle('active');

          // Find the associated body and toggle its display
          const body = header.nextElementSibling as HTMLElement | null;
          if (body) {
            body.classList.toggle('show');
          }
        });
      });

      // Cleanup event listeners on component unmount
      return () => {
        accordions.forEach(header => {
          header.removeEventListener('click', () => {
            header.classList.toggle('active');
            const body = header.nextElementSibling as HTMLElement | null;
            if (body) {
              body.classList.toggle('show');
            }
          });
        });
      };
    };

    updateAccordion();
  }, [htmlBody]);
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
  )
}


export default Privacy;