import axios from "axios";

const loadBaseUrl = () => {
    let configStorage: any = sessionStorage.getItem('config');
    if (configStorage) {
        configStorage = JSON.parse(configStorage);
        axios.defaults.baseURL = configStorage.apiEndPoint;
        axios.defaults.headers.common = {"Content-Type": "application/json", }
    }
}

axios.interceptors.request.use(
    (config: any) => {
        config.withCredentials = true;
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Methods"] =
            "GET,PUT,POST,DELETE,PATCH,OPTIONS";

        // add token in config after api creation
        return config;
    },
    (error: any) => {
        return error;
    }
);

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    loadBaseUrl: loadBaseUrl
};

export default http;