import React, { useState } from 'react'

import DOMPurify from "isomorphic-dompurify";
import { ButtonField, ButtonGroup, ButtonGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from '@wk/components-react16';

const PaymentsLearnMoreContitions = (props: any) => {
  const pageUrl = props.config.assetsUrl + '/contents/learnMorePayments.html';
  const [htmlBody, setHtmlBody] = useState('');
  
  // read the contents from source and sanitize it
  React.useEffect(() => {		
    fetch(pageUrl)
    .then(response => response.text())
    .then(text => {
      setHtmlBody(DOMPurify.sanitize(text));
  });
	}, []);// eslint-disable-line

  return (
    <Modal
                    isOpen={props.isOpen}
                    srLabelledBy={'modal-id-001'}
                    srDescribedBy={'modal-id-002'}
                    onUserRequest={(event: any) => props.handleUserRequest()}>
                    <ModalHeader
                        titleId={'modal-id-001'}
                        onUserRequest={() => props.handleUserRequest()}>
                        Terms & Conditions
                    </ModalHeader>
                    <ModalBody descriptionId="modal-id-002">
                        <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup mode="static">
                            <ButtonGroupItem slot="buttonGroupItem">
                                <ButtonField>
                                    <button
                                        type="button"
                                        id="cancel"
                                        onClick={() => props.handleUserRequest()}>
                                        Close
                                    </button>
                                </ButtonField>
                            </ButtonGroupItem>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
    
  )
}


export default PaymentsLearnMoreContitions;