import {
    ButtonField,
    
    CheckboxField,
    InputField,
    
    RadioField,
    RadioGroupField,
    SelectField,
} from "@wk/components-react16";
import React, { useEffect, useRef, useState } from "react";
import ApplePay from "./applepay";
import { embedFlexMicroformScript } from './embedMicroform';
import { KeyValuePair } from "../models/keyvalue";
import paymentService from "../services/paymentService";
import checkoutService from "../services/checkoutService";
import './microformStyles.css'
import PaymentsLearnMoreContitions from "../checkout/paymentLearnMoreCondition";
import { GooglePay } from "./googlePay";


declare global {
    interface Window {
        Flex: any;
        google: any;
    }
}

const PaymentInformation = (props: any) => {
    const [selectedOption, setSelectedOption] = useState('credit');
    const [isOpen, setIsOpen] = useState(false);
    const [countries, setCountries] = useState<KeyValuePair[]>([] as KeyValuePair[])
    const [provinces, setProvinces] = useState<KeyValuePair[]>([] as KeyValuePair[])
    // const [loadMicroform, setLoadMicroform] = useState(false);
    const [microform, setMicroform] = useState<any>(null);
    const ccNumberRef = useRef(null);
    const cvvRef = useRef(null);
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [errors, setErrors] = useState<any>({});
    
    const { user, setUser, handleTaxUpdateChange, handleZipCodeRemoval, resetTaxForProduct} = props;

    const handleRadioChange = (event: any) => {
        setSelectedOption(event.target.value);
    };
    const openModal = () => {
        setIsOpen(true)
    }
    const handleUserRequest = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        loadFlexMicroform(props.config.cybersourceAssetURL)

        const fetchProvinces = async () => {
            var result = await checkoutService.GetStates(37);
            setProvinces(result.data.map((state: any) => ({ key: state.stateCode, value: state.stateName })));
        }
        fetchProvinces();

    }, []) //eslint-disable-line

    useEffect(() => {
        setupFlexMicroform();
    }, [microform]); //eslint-disable-line

    React.useEffect(() => {
        const fetchCountries = async () => {
            if (selectedOption === 'credit') {
                try {
                    const response = await checkoutService.GetCountries();
                    setCountries(response.data);
                    setUser({ ...user, countryId: response.data[0].key, country: response.data[0].value });
                } catch (err) {
                    console.error('Error fetching countries:', err);
                }
            }
        };

        fetchCountries();
    }, [selectedOption]); //eslint-disable-line


    const handleZipCodeChange = async (e: any) => {
        let zipCode = e.target.value.slice(0, 5);

        if (/^[0-9]*$/.test(zipCode)) {
            setUser({ ...user, zipCode: zipCode });
            setErrors({...errors, zipCode: ""})

            if (zipCode !== user.zipCode && zipCode.length === 5){
                handleTaxUpdateChange(zipCode).then((isTaxValid) => {
                    if(!isTaxValid)
                        setErrors({...errors, zipCode: "Entered zip code is invalid"})
                })
                
            }
            else
                handleZipCodeRemoval()
        }
        if(zipCode.length < 5 || zipCode.length > 5)
            setErrors({...errors, zipCode: "Please enter valid zip code"})
        else
            setErrors({...errors, zipCode: ""})

    }

    const handleCountryChange = async ({ target }: any) => {
        const selectedCountryId = target.value;
        
        // keep country Ids in config for province selection
        if (+selectedCountryId === 37) {            
            setUser({ ...user, zipCode:'', countryId: +selectedCountryId, country: countries.filter(a=> a.key === selectedCountryId)[0].value, state: provinces[0].value, stateId: provinces[0].key})
        }
        else{
            setUser({...user, zipCode:'', state:'', stateId: 0, countryId: +selectedCountryId, country: target.options[target.selectedIndex].text })
        }
        
        resetTaxForProduct();
    }

    const handleExpiryMonthChange = (month: string) => {
        if (/^[0-9]*$/.test(month) && month.length <= 2) {
            if (+month <= 12){
                setExpiryMonth(`${month}`);
                setErrors({ ...errors, month: "" })
            }
            if(+month === 0)
                setErrors({ ...errors, month: "Please enter valid expiry month" })
        }
    }

    const handleExpiryYearChange = (year: string) => {
        if (/^[0-9]*$/.test(year)) {
            setExpiryYear(year.slice(0, 4));
            if (+year < new Date().getFullYear() || +year === 0)
                setErrors({ ...errors, year: "Please enter valid expiry year" })
            else
                setErrors({ ...errors, year: "" })
        }
    }

    var customStyles = {
        '*': {
            'font-family': 'Fira Sans'
        },
        'input': {
            'font-size': '1rem;',
            'font-family': 'Fira Sans',
            'color': '#232323',
            'padding-left': '0.5rem',
            'padding-right': '0.5rem',
            'line-height': '1.25',
            'height': '2.5rem',
        },
        '::placeholder': {
            'color': '#232323','font-weight': '300', 'opacity': '1'
        },
        ':focus': {
          'color': 'blue'
        },
        'valid': {
          'color': 'green'
        },
        'invalid': {
          'color': 'red'
        }
      };

    const loadFlexMicroform = async (cyberSourceURL: any) => {
        embedFlexMicroformScript(cyberSourceURL)
            .then(async () => {
                const key = await paymentService.GetPublicKey();
                const flex = new window.Flex(key);
                setMicroform(flex.microform({ styles: customStyles }));
            })
            .catch(window.console.error);
    }

    const setupFlexMicroform = () => {
        if (!microform) {
            return;
        }

        const number = microform.createField("number", {
            placeholder: "Enter Your Credit Card Number"
        });
        
        number.on('change', function(data) {
            if(!data.valid && !data.couldBeValid){
                setErrors({...errors, cardNumber: 'Please enter valid credit card number'})
            }
            else{
                setErrors({...errors, cardNumber: ''})
            }
          });

        const securityCode = microform.createField("securityCode", {
            placeholder: "CVV"
        });

        securityCode.on('change', function(data) {
            if(!data.valid && !data.couldBeValid){
                setErrors({...errors, cvv: 'Please enter valid CVV'})
            }
            else{
                setErrors({...errors, cvv: ''})
            }
          });
          
        // load microform elements
        number.load(ccNumberRef.current);
        securityCode.load(cvvRef.current);
    };

    const handleCardPayment = () => {
        const invalidFields = validatePaymentFields();
            if (microform) {
                const options = {
                    expirationMonth: expiryMonth,
                    expirationYear: expiryYear
                };

                microform.createToken(options, function (err: any, token: any) {
                    if(token && !Object.keys(invalidFields).filter((a: any) => invalidFields[a].length).length)
                        props.handlePayment(token, 1);
                    if(err){
                        if(err.details && err.details.length){
                            if(err.details.filter(a=> a.location === 'securityCode').length){
                                invalidFields.cvv ='Please enter valid CVV'
                            }
                            if(err.details.filter(a=> a.location === 'number').length){
                                invalidFields.cardNumber = 'Please enter valid credit card number'
                            }
                            else if (err.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE') {
                                props.setShowModal(true);
                                props.setModalHeader('Session Timeout');
                                props.setModalMessage('Due to inactivity for long time session expired, please try clicking Pay again');
                                if (props.config.cybersourceAssetURL) {
                                    loadFlexMicroform(props.config.cybersourceAssetURL);
                                }
                            }
                        }
                        // console.log(err);
                    }
                    if(Object.keys(invalidFields).filter((a: any) => invalidFields[a]).length)
                        setErrors(invalidFields);
                })
            }
    }

    const handleProvinceChange = ({target}) => {
        setUser({ ...user, stateId: target.value, state: provinces.filter(a=> a.key === target.value)[0].value })
        handleTaxUpdateChange(provinces.filter(a=> a.key === target.value)[0].value, true)
    }

    const validatePaymentFields = () => {
        let inputErrors = {...errors};

        if(!user.countryId)
            inputErrors.country = "Please select country"

        if (!user.firstName)
            inputErrors.firstName = "Please enter first name"

        if (!user.lastName) 
            inputErrors.lastName = "Please select last name"

        if(!user.zipCode && +user.countryId === 192)
            inputErrors.zipCode = "Please enter valid zipcode"
        
        if(!user.stateId && +user.countryId === 37)
            inputErrors.province = "Please select province"
        
        if(!expiryMonth)
            inputErrors.month = "Please enter valid expiry month"

        if(!expiryYear)
            inputErrors.year = "Please enter valid expiry year"

        if(!agreeTerms)
            inputErrors.agreeTerms = "Please read and agree the terms"

        return inputErrors;

    }

   

    return (
        <>
            <div>
                <div className="wk-grid-page-container">
                    <div className="wk-grid custom-padding">
                        <div className="wk-column-12">
                            <RadioGroupField alignment="horizontal">
                                <RadioField label="Credit Card">
                                    <input
                                        type="radio"
                                        name="Credit"
                                        value="credit"
                                        checked={selectedOption === "credit"}
                                        onChange={handleRadioChange} />
                                </RadioField>
                                {props.config.applePay.enable &&
                                    <RadioField label="Apple Pay">
                                        <input
                                            type="radio"
                                            name="apple-pay"
                                            value="applepay"
                                            checked={selectedOption === "applepay"}
                                            onChange={handleRadioChange} />
                                    </RadioField>
                                }
                                {props.config.googlePay.enable &&
                                    <RadioField label="Google Pay">
                                        <input
                                            type="radio"
                                            name="google-pay"
                                            value="gpay"
                                            checked={selectedOption === "gpay"}
                                            onChange={handleRadioChange} />
                                    </RadioField>
                                }
                            </RadioGroupField>
                        </div>
                        {selectedOption === "credit" && (
                            <>
                                <div className="wk-column-5 wk-column-12-xs">
                                    <InputField label="First Name" labelFor="input-id-1-1" indicator="required">
                                        <input
                                            type="text"
                                            id="input-id-1-1"
                                            name="input-1-1"
                                            placeholder="Enter First Name"
                                            value={user?.firstName || ''}
                                            onChange={(e) => { setUser({ ...user, firstName: e.target.value }); if(e.target.value.length > 0) setErrors({...errors, firstName:''})}}
                                        />
                                    {errors && errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                                    </InputField>
                                </div>
                                <div className="wk-column-5 wk-column-12-xs">
                                    <InputField label="Last Name" labelFor="input-id-1-1" indicator="required">
                                        <input
                                            type="text"
                                            id="input-id-1-1"
                                            name="input-1-1"
                                            placeholder="Enter Last Name"
                                            value={user?.lastName || ''}
                                            onChange={(e) => {setUser({ ...user, lastName: e.target.value }); if(e.target.value.length > 0) setErrors({...errors, lastName:''})}}
                                        />
                                    {errors && errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                                    </InputField>
                                </div>
                                <div className="wk-column-5 wk-column-12-xs">
									<div className="cg-field-header" style={{marginBottom:'.09rem'}}>
										<label className="required" style={{fontWeight:500}}>Credit Card</label> 
									</div>
									<div id="ccContainer" ref={ccNumberRef}></div>
                                    {errors && errors.cardNumber && <div className="text-danger">{errors.cardNumber}</div>}
								</div>
                                <div className="wk-column-5 wk-column-12-xs">
                                    <div className="wk-grid custom-padding">
                                        <div className="wk-column-4 wk-column-12-xs">
                                            <InputField label="Month" labelFor="input-id-1-1" indicator="required">
                                                <input
                                                    type="text"
                                                    id="input-id-1-1"
                                                    name="input-1-1"
                                                    placeholder="MM" value={expiryMonth}
													onChange={(e) => handleExpiryMonthChange(e.target.value)}/>
											{errors && errors.month && <div className="text-danger">{errors.month}</div>}
											</InputField>
                                        </div>
                                        <div className="wk-column-4 wk-column-12-xs">
                                            <InputField label="Year" labelFor="input-id-1-1" indicator="required">
                                                <input
                                                    type="text"
                                                    id="input-id-1-1"
                                                    name="input-1-1"
                                                    placeholder="YYYY" value={expiryYear}
													onChange={(e) => handleExpiryYearChange(e.target.value)}/>
											{errors && errors.year && <div className="text-danger">{errors.year}</div>}
											</InputField>
                                        </div>
                                        <div className="wk-column-4 wk-column-12-xs">
											<div className="cg-field-header" style={{marginBottom:'.09rem'}}>
												<label className='required' style={{fontWeight:500 }}>CVV</label>
											</div>
											<div id="cvvContainer" ref={cvvRef}></div>
                                            {errors && errors.cvv && <div className="text-danger">{errors.cvv}</div>}
										</div>
                                    </div>
                                </div>
                                <div className="wk-column-5 wk-column-12-xs">
                                    <SelectField
                                        label="Country"
                                        labelFor="country"
                                        indicator="required"
                                    >
                                        <select id="country" name="story1-1"
                                            value={user?.countryId || 0}
                                            onChange={handleCountryChange}>
                                            {
                                                countries.map((designation: KeyValuePair, index: number) => <option key={index} value={designation.key}>{designation.value}</option>)
                                            }
                                        </select>
                                        {errors && errors.country && <div className="text-danger">{errors.country}</div>}
                                    </SelectField>
                                </div>

                                {+user.countryId === 192 ?
                                    <div className="wk-column-5 wk-column-12-xs">
                                        <InputField label="Zip Code" labelFor="input-id-1-1" indicator="required">
                                            <input
                                                type="text"
                                                id="input-id-1-1"
                                                name="input-1-1"
                                                placeholder="Zip"
                                                value={user.zipCode || ''}
                                                pattern="[0-9]*"
                                                onChange={handleZipCodeChange} />
                                        {errors && errors.zipCode && <div className="text-danger">{errors.zipCode}</div>}
                                        </InputField>
                                    </div>
                                    : +user.countryId === 37 ?
                                    <div className="wk-column-5 wk-column-12-xs">
                                        <SelectField
                                            label="Province"
                                            labelFor="province"
                                            indicator="required"
                                        >
                                            <select id="province" name="story1-1"
                                                value={user?.stateId || 0}
                                                onChange={handleProvinceChange}>
                                                {
                                                    provinces.map((province: KeyValuePair, index: number) => <option key={index} value={province.key}>{province.value}</option>)
                                                }
                                            </select>
                                            {errors && errors.province && <div className="text-danger">{errors.province}</div>}
                                        </SelectField>
                                    </div>
                                    : <></>
                                }
                                <div className="wk-column-12 wk-column-12-xs">
                                    <p>
                                        Review your order details and press the Place Order button to
                                        finalize your purchase.
                                    </p>
                                    <p>
                                        If you are not completely satisfied with your OnDemand
                                        product(s), you may cancel within 7 business days of purchase
                                        and receive a prompt refund.
                                    </p>
                                </div>
                                <div className="wk-column-12 wk-column-12-xs display-flex flex-items-center">
                                    <CheckboxField label="I agree to the ">
                                        <input name="checkbox1" type="checkbox"  checked={agreeTerms} onChange={()=> {setAgreeTerms(!agreeTerms); setErrors({...errors, agreeTerms: !agreeTerms ? "" : "Please read and agree the terms"}) }}/>
                                    </CheckboxField><span className="required-star">*</span><span className="link-text" onClick={openModal}>Terms and Conditions</span>
                                </div>
                                <div className="wk-column-12 wk-column-12-xs" style={{marginTop:'-1rem'}}>
                                    {errors && errors.agreeTerms && <div className="text-danger">{errors.agreeTerms}</div>}
                                    </div>
                                <div className="wk-column-12 wk-column-12-xs">
                                    <ButtonField>
                                        <button type="button" style={{ marginRight: "5px" }} onClick={handleCardPayment}>
                                            Place Order
                                        </button>
                                    </ButtonField>
                                </div>
                            </>
                        )}
                        {selectedOption === "applepay" && (
                            <ApplePay />
                        )}
                        {selectedOption === "gpay" && (
                            <GooglePay config={props.config} user={user} setUser={setUser} countries={countries} provinces={provinces} handlePayment={props.handlePayment} product={props.product} handleUserAndProductUpdate={props.handleUserAndProductUpdate} />
                        )}
                         <div className="wk-column-12 wk-column-12-xs">
                            <span className="required">Required Field(s)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>

                <PaymentsLearnMoreContitions config={props.config} isOpen={isOpen} handleUserRequest={handleUserRequest} />
            </div>
           </>
    );
};

export default PaymentInformation;
