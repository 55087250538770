export const embedFlexMicroformScript = (cyberSourceURL) => {
    if (!window.Flex) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = cyberSourceURL;
            script.onload = () => resolve();
            script.onerror = (err) => reject(err.message);
            document.head.append(script);
        });
    }
    return Promise.resolve();
};