import React, { useEffect, useRef, useState } from 'react'
import { CheckoutProduct } from '../models/product';
import User from "../models/user";
import checkoutService from "../services/checkoutService";

export const GooglePay = (props: any) => {
    const { user, countries, provinces, handlePayment, handleUserAndProductUpdate } = props;
    const productRef: any = useRef();
    const [productCopy, setProductCopy] = useState<CheckoutProduct>({} as CheckoutProduct);
    const [tax, setTax] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        if (!Array.from(document.scripts).filter(a => a.src.includes('google')).length) {
            const script = document.createElement('script');
            script.src = props.config.googlePay.scriptUrl;
            script.onload = () => onGooglePayLoaded();
            document.head.append(script);
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        console.log(props.product)
        setProductCopy(props.product);
        productRef.current = props.product
    }, [props.product])

    useEffect(() => {
        if (total) {
            setProductCopy({ ...productRef.current, totalTax: +tax, orderGrandTotal: +total });

        }
    }, [tax, total])  //eslint-disable-line

    useEffect(() => {
        productRef.current = productCopy as any;
    }, [productCopy])


    function getGoogleConfigs(key) {

        let value;
        switch (key) {
            case 'gateway':
                value = props.config.gateway;
                break;
            case 'gatewayMerchantId':
                value = props.config.gatewayMerchantId;
                break;
            case 'merchantId':
                value = props.config.googlePay.merchantId;
                break;
            case 'merchantName':
                value = props.config.googlePay.merchantName;
                break;
            case 'googlePayEnvironment':
                value = props.config.googlePay.environment;
                break;
            case 'enableGooglePay':
                value = props.config.googlePay.enable;
                break;
        }
        return value;
    }

    async function processPayment(paymentData) {
        const paymentToken = paymentData.paymentMethodData.tokenizationData.token;

        var cardTypeAndMaskedPan = getCardTypeAndMaskedPanFromGoogleResponse(paymentData.paymentMethodData);

        const firstName = paymentData.shippingAddress.name.split(' ')[0];
        const lastName = paymentData.shippingAddress.name.split(' ')[1]
        const newUser: User = { ...user }
        newUser.firstName = firstName;
        newUser.lastName = lastName;
        newUser.state = paymentData.shippingAddress.administrativeArea;
        newUser.country = paymentData.shippingAddress.countryCode;
        newUser.countryId = ['us', 'united states', 'usa'].includes(paymentData.shippingAddress.countryCode.toLocaleLowerCase()) ? 192 : ['canada', 'ca'].includes(paymentData.shippingAddress.countryCode.toLocaleLowerCase()) ? 37 : 0;
        newUser.zipCode = paymentData.shippingAddress.postalCode;

        await handlePayment(window.btoa(paymentToken), 2, cardTypeAndMaskedPan, newUser, productRef.current);
        return ""
    }

    function getGoogleShippingAddressParameters() {
        return {
            //allowedCountryCodes: ['US', 'CA', 'UK'],
            phoneNumberRequired: true
        };
    }


    const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0
    };

    const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];
    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];


    const tokenizationSpecification = {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            'gateway': getGoogleConfigs('gateway'),
            'gatewayMerchantId': getGoogleConfigs('gatewayMerchantId')
        }
    };

    function getGoogleTransactionInfo() {

        let priceForTax = productRef.current.priceWithoutTax

        let regularPrice = productRef.current.priceWithoutTax + (productRef.current.membershipDiscount ? productRef.current.membershipDiscount : 0) //model.Cart.CartLines[0].ProductRegularPrice;
        let tax = productRef.current.totalTax
        let newTotal = productRef.current.orderGrandTotal

        tax = 0.00;

        newTotal = priceForTax

        var items = [{
            label: "Price",
            type: "LINE_ITEM",
            price: regularPrice.toString(),
        }];

        if (productRef.current.membershipDiscount) {
            items.push({
                label: "Discount",
                type: "LINE_ITEM",
                price: `-${productRef.current.membershipDiscount}`
            });
        }

        items.push({
            label: "Tax",
            type: "TAX",
            price: tax.toString()
        });

        return {
            displayItems: items,
            countryCode: 'US',
            currencyCode: "USD",
            totalPriceStatus: "FINAL",
            totalPrice: newTotal.toString(),
            totalPriceLabel: "Total"
        };
    }

    const baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: allowedCardAuthMethods,
            allowedCardNetworks: allowedCardNetworks
        }
    };

    const cardPaymentMethod = Object.assign(
        {},
        baseCardPaymentMethod,
        {
            tokenizationSpecification: tokenizationSpecification
        }
    );

    let paymentsClient = null;

    function getGoogleIsReadyToPayRequest() {
        return Object.assign(
            {},
            baseRequest,
            {
                allowedPaymentMethods: [baseCardPaymentMethod]
            }
        );
    }

    function getGooglePaymentDataRequest() {
        const paymentDataRequest: any = Object.assign({}, baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        paymentDataRequest.merchantInfo = {
            merchantId: getGoogleConfigs('merchantId'),
            merchantName: getGoogleConfigs('merchantName')
        };

        paymentDataRequest.callbackIntents = ["PAYMENT_AUTHORIZATION", "SHIPPING_ADDRESS", "SHIPPING_OPTION"];

        paymentDataRequest.shippingAddressRequired = true;
        paymentDataRequest.shippingAddressParameters = getGoogleShippingAddressParameters();
        paymentDataRequest.shippingOptionRequired = true;

        return paymentDataRequest;
    }

    function getGooglePaymentsClient() {
        if (paymentsClient === null) {
            paymentsClient = new window.google.payments.api.PaymentsClient({
                environment: getGoogleConfigs('googlePayEnvironment'),
                paymentDataCallbacks: {
                    onPaymentAuthorized: onPaymentAuthorized,
                    onPaymentDataChanged: onPaymentDataChanged
                }
            });
        }
        return paymentsClient;
    }

    async function onPaymentDataChanged(intermediatePaymentData) {
        let shippingAddress = intermediatePaymentData.shippingAddress;
        let shippingOptionData = intermediatePaymentData.shippingOptionData;
        let paymentDataRequestUpdate: any = {};

        if (intermediatePaymentData.callbackTrigger === "INITIALIZE" || intermediatePaymentData.callbackTrigger === "SHIPPING_ADDRESS") {

            paymentDataRequestUpdate.newShippingOptionParameters = getGoogleDefaultShippingOptions();

            const countryId = ['us', 'united states', 'usa'].includes(shippingAddress.countryCode.toLocaleLowerCase()) ? 192 : ['canada', 'ca'].includes(shippingAddress.countryCode.toLocaleLowerCase()) ? 37 : 0;

            var taxTotal = await CalculateTaxForAppleOrGooglePay(shippingAddress.countryCode, shippingAddress.postalCode, shippingAddress.administrativeArea, countryId)

            const userupdate = { ...user, countryId: countryId, country: countries.filter(a => a.key === `${countryId}`)[0].value, state: shippingAddress.administrativeArea, stateId: countryId === 37 ? provinces.filter(a => a.value === shippingAddress.administrativeArea)[0].value ?? 0 : 0 }
            const productUpdate = { ...productRef.current, orderGrandTotal: +taxTotal.totalPrice, totalTax: +taxTotal.displayItems.filter(a => a.type === 'TAX')[0].price }

            handleUserAndProductUpdate(userupdate, productUpdate)
            setTax(+taxTotal.displayItems.filter(a => a.type === 'TAX')[0].price);
            setTotal(+taxTotal.totalPrice);

            paymentDataRequestUpdate.newTransactionInfo = taxTotal 
            return paymentDataRequestUpdate;
        }
        else {
            paymentDataRequestUpdate.newTransactionInfo = calculateNewTransactionInfo(shippingOptionData.id);
            return paymentDataRequestUpdate;
        }


    }

    async function CalculateTaxForAppleOrGooglePay(countryCode: string, zipCode: string, stateCode: string, countryId: number) {

        const tax = await checkoutService.AppliedTaxByCountryCode(countryCode, countryId, stateCode, zipCode, productRef.current.priceWithoutTax)

        let newTransactionInfo = getGoogleTransactionInfo();

        newTransactionInfo.displayItems.filter(a => a.type === 'TAX')[0].price = `${tax.salesTaxAmount.toFixed(2)}`;
        newTransactionInfo.totalPrice = tax.totalAmount.toFixed(2).toString();

        return newTransactionInfo;
    }


    async function onPaymentAuthorized(paymentData) {
        const response = await processPayment(paymentData)
        if (response === "") {
            return { transactionState: 'SUCCESS' }
        }
        else {
            return {
                transactionState: 'ERROR',
                error: {
                    intent: 'PAYMENT_AUTHORIZATION',
                    message: String(response),
                    reason: 'PAYMENT_DATA_INVALID'
                }
            }
        }
    }

    function onGooglePayLoaded() {
        const paymentsClient: any = getGooglePaymentsClient();
        paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
            .then(function (response) {
                if (response.result) {
                    addGooglePayButton();
                }
            })
            .catch(function (err) {
                console.error(err);
            });
    }

    function addGooglePayButton() {
        const paymentsClient: any = getGooglePaymentsClient();
        const button =
            paymentsClient.createButton({
                onClick: onGooglePaymentButtonClicked,
                allowedPaymentMethods: [baseCardPaymentMethod]
            });

        document.getElementById('container')?.appendChild(button);
    }

    function onGooglePaymentButtonClicked() {
        const paymentDataRequest: any = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

        const paymentsClient: any = getGooglePaymentsClient();
        paymentsClient.loadPaymentData(paymentDataRequest).catch(function (err) {
            console.log(err)
            if (err.statusCode === "CANCELED")
                console.log('USER CANCELLED PAY REQUEST');
        });;
    }

    function getCardTypeAndMaskedPanFromGoogleResponse(paymentResponse) {

        let cardType = '';
        if (paymentResponse.info.cardNetwork.toLowerCase() === "amex")
            cardType = '003'
        else if (paymentResponse.info.cardNetwork.toLowerCase() === "discover")
            cardType = '004'
        else if (paymentResponse.info.cardNetwork.toLowerCase() === "mastercard")
            cardType = '002'
        else if (paymentResponse.info.cardNetwork.toLowerCase() === "visa")
            cardType = '001'
        else
            cardType = '001'

        return {
            cardType: cardType,
            maskedPan: paymentResponse.info.cardDetails
        }
    }


    function getGoogleDefaultShippingOptions() {
        return {
            defaultSelectedOptionId: "shipping-001",
            shippingOptions: [
                {
                    "id": "shipping-001",
                    "label": "Digitally shipped via email",
                }
            ]
        };
    }


    function calculateNewTransactionInfo(shippingOptionId) {
        let newTransactionInfo = getGoogleTransactionInfo();

        if (shippingOptionId === "shipping_option_unselected") {
            shippingOptionId = "shipping-001";
        }

        let totalPrice = 0.00;
        newTransactionInfo.displayItems.forEach(displayItem => totalPrice += parseFloat(displayItem.price));
        newTransactionInfo.totalPrice = totalPrice.toFixed(2).toString();

        return newTransactionInfo;
    }
    return (
        <>
            <div id="container" className="float-left"></div>
        </>
    )
}
