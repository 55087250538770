import { ButtonField } from '@wk/components-react16';
const NotFound = () => {
    return (
      <div className='white-bg not-found-height'>
        <div className="wk-grid-page-container docs-grid-container w-85vw">
          <div className="wk-grid pl-0 horizontal-center-align">
            <div className="wk-column-12 wk-column-12-xs">
            <div><h3 className="oops-text">Oops!</h3>
            </div>
            </div>
            <div>
            <div className="wk-column-12 wk-column-12-xs">
                The page you're looking for wasn't found.
                </div>
            </div>
            <div className="wk-column-12 wk-column-12-xs">
            <a href="/">
            <ButtonField mode={'ghost'}>
                <button type="button" className="wkbutton-link field-link" style={{ marginRight: "5px" }}>
                    Return To Home
                </button>
                </ButtonField>
            </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default NotFound;




