import React, { useEffect, useState } from "react";
import {
	ButtonField,
	InputField,
	PasswordField,
	SelectField,
	ButtonGroup,
	ButtonGroupItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "@wk/components-react16";
import { KeyValuePair } from "../models/keyvalue";
import User from "../models/user";
import checkoutService from "../services/checkoutService"

const AccountDetails = (props: any) => {
	const [passwordVisibility, setPasswordVisibility] = React.useState({
		password: false,
		confirmPassword: false,
	});

	const { user, setUser, setLoading, checkoutProduct } = props;

	const [accountSteps, setAccountSteps] = useState('membership-verification');
	const [designations, setDesignations] = React.useState<KeyValuePair[]>([] as KeyValuePair[]);
	const [errors, setErrors] = useState<any>({});
	const [disableEmailField, setDisableEmailField] = useState(false);
	const [ssoLastName, setSSOLastName] = useState('');

	const closeModal = () => setModalPopupVisibility(false);

	React.useEffect(() => {
		document.getElementById('email')?.focus();
	}, [])

	const togglePasswordVisibility = (field: 'password' | 'confirmPassword') => {
		setPasswordVisibility({
			...passwordVisibility,
			[field]: !passwordVisibility[field],
		});
	};

	const [showModalPopupVisibility, setModalPopupVisibility] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	useEffect(() =>{
		console.log(errors)
	}, [errors.email]) //eslint-disable-line

	useEffect(() => {
		const fetchDesignations = async () => {
			if (user.isNewUser && !user.isSSOUser && !designations.length) {
				try {
					const result = await checkoutService.fetchDesignation();
					setDesignations(result.data);
				} catch (error) {
					console.error('Error fetching designations:', error);
				}
			}
		}
		fetchDesignations();
	}, [user.isNewUser]) //eslint-disable-line

	const handleEmailChange = (email: string) => {
		const isEmailValid = IsValidEmailAddress(email);
		if (isEmailValid) {
			checkoutService.CreateTinyUser(email, checkoutProduct.product.trackType, checkoutProduct.product.trackPage, checkoutProduct.product.thinkProductID)
			setAccountSteps('membership-verification')
		}

		setUser({ ...user, email: email, accountSetupComplete: false });
	}

	const validatePassword = (password: string) => {
		if (!new RegExp(/^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*[0-9])+)(?=(.*[!@#$%^&;*()\-__+.])+).{12,128}$/).test(password))
			return "Password must be at least 12 characters long and should include each of the following: An uppercase letter, a lowercase letter, a number and a special character."
		else
			return ""
	}

	const handleSSOLastName = (ssoLastName: string) => {
		setSSOLastName(ssoLastName);
		setUser({ ...user, lastName: ssoLastName });
		if (ssoLastName)
			setErrors({ ...errors, ssoLastName: "" })
	}

	const handlePasswordChange = (password: string) => {
		const inputErrors = { ...errors }

		inputErrors.password = validatePassword(password);

		if (user.confirmPassword && user.confirmPassword !== password)
			inputErrors.confirmPassword = "Passwords do not match."
		else
			inputErrors.confirmPassword = ""

		setErrors(inputErrors);
		setUser({ ...user, password: password })
	}

	const handleConfirmPasswordChange = (confirmPassword: string) => {
		if (confirmPassword !== user.password)
			setErrors({ ...errors, confirmPassword: "Passwords do not match." })
		else
			setErrors({ ...errors, confirmPassword: "" })

		setUser({ ...user, confirmPassword: confirmPassword })
	}

	const IsValidEmailAddress = (emailAddress: string) => {
		let isValid = false;
		let emailId = emailAddress && emailAddress.trim();
		const inputErrors = { ...errors }
		if (emailId) {
			const flags = "gm";
			const pattern = "[A-Za-z0-9\\._%+\\-]+@[A-Za-z0-9\\.\\-]+\\.[A-Za-z]{2,}"
			const regexPattern = new RegExp(pattern, flags);

			if (!emailId.match(regexPattern))
				inputErrors.email = "Please enter a valid email address.";
			else {
				isValid = true;
				inputErrors.email = ""
			}
		}
		else
			inputErrors.email = "Please enter email address.";

		setErrors(inputErrors);
		return isValid;
	}

	const handleProfessionalDesignationChange = (e: any) => {
		const inputErrors = { ...errors };

		if (+e.target.value) {
			inputErrors["professionalDesignation"] = "";
		}
		else
			inputErrors["professionalDesignation"] = "Professional Designation is required."

		setUser({ ...user, professionalDesignation: e.target.value })
		setErrors(inputErrors)
	}

	const handleCustomerSubmit = (e: any) => {
		e.preventDefault();
		if (accountSteps === 'membership-verification')
			accountValidateDuplicateOrder();
		if (accountSteps === 'member-verification-complete') {
			if (user.isNewUser) {
				const invalidFields = validateNewUserFields()
				if (invalidFields) {
					return;
				}
			}
			setUser({ ...user, accountSetupComplete: true })
			props.handleCompleteAccountSetup(user.email);
		}
	}

	const validateNewUserFields = () => {
		const inputErrors = { ...errors };
		!+user.professionalDesignation
			? inputErrors.professionalDesignation = "Professional Designation is required."
			: inputErrors.professionalDesignation = ""

		if (!checkoutProduct.product.isSSO) {
			user.password
				? inputErrors.password = validatePassword(user.password)
				: inputErrors.password = "Password is required."

			!user.confirmPassword
				? inputErrors.confirmPassword = "Confirm Password is required."
				: inputErrors.confirmPassword = "";

			user.password && user.confirmPassword && user.password !== user.confirmPassword
				? inputErrors.confirmPassword = "Passwords do not match."
				: inputErrors.confirmPassword = inputErrors.confirmPassword ? inputErrors.confirmPassword : "";
		}

		setErrors(inputErrors)
		return Object.keys(inputErrors).filter((a: any) => inputErrors[a]).length;
	}

	const accountValidateDuplicateOrder = async () => {
		if (checkoutProduct.product.showLastName && !ssoLastName) {
			setErrors({ ...errors, ssoLastName: "Last name is required." });
			return;
		}
		if (user.email && !errors.email) {
			setErrors({ ...errors, ssoLastName: '' });
			setLoading(true);
			let productID = checkoutProduct.product.thinkProductID;
			const data = await checkoutService.IsUserExist(user.email, productID);
			setLoading(false);
			setDisableEmailField(true)
			if (data) {
				if (data.isDuplicateOrder) {
					setModalMessage("You have already purchased this product.");
					setModalPopupVisibility(true);
				}
				else if (!checkoutProduct.product.isSSO && data.isUserExistInThink && data.isSSOUser) {
					setModalMessage("This email address is already in use. Please use different email address.");
					setModalPopupVisibility(true);
				}
				else {
					user.isNewUser = !data.isUserExistInThink;
					user.isSSOUser = data.isSSOUser;
					accountMembershipVerification(user);
				}
			}
		}
		else {
			setErrors({ ...errors, email: "Email address is required." });
		}
	}

	const accountMembershipVerification = async (user: User) => {
		if (checkoutProduct.product.isSSO) {
			if (checkoutProduct.product.showLastName && (!user.lastName || errors.lastName)) {
				return;
			}
			else {
				setLoading(true);
				let result = await checkoutService.SSOMembership(checkoutProduct.product.societyAcronym, user.email, user.lastName, checkoutProduct.product.meetingID, checkoutProduct.product.trackPage, checkoutProduct.product.trackType);
				var data = result;
				if (data.customer && data.discounts) {
					user.ssoCustomerId = data.customer.ssoCustomerId
					props.handleMemberCheckoutDiscount(data.discounts);

					if (!data.customer.profDesignationId)
						user.professionalDesignation = '01' // MD
					else
						user.professionalDesignation = data.customer.profDesignationId
				}

				if (!user.professionalDesignation)
					user.professionalDesignation = '01' // MD

				setLoading(false);
				setAccountSteps('member-verification-complete');
				user.accountSetupComplete = true;
				props.handleCompleteAccountSetup(user.email);
				setUser(user)
			}
		}
		else {
			setLoading(true);
			let data = await checkoutService.NonSSOMembership(checkoutProduct.product.societyAcronym, user.email, user.lastName, checkoutProduct.product.meetingID, checkoutProduct.product.trackPage, checkoutProduct.product.trackType);

			if (data.customer && data.discounts) {
				props.handleMemberCheckoutDiscount(data.discounts);

				if (data.customer.profDesignationId)
					user.professionalDesignation = data.customer.profDesignationId
			}

			if (!user.isNewUser && !user.professionalDesignation)
				user.professionalDesignation = '01' // MD

			setAccountSteps('member-verification-complete')
			setLoading(false);

			if (!user.isNewUser) {
				user.accountSetupComplete = true;
				props.handleCompleteAccountSetup(user.email);
				setUser(user)
			}
		}

	}

	return (
		<div>
			{/*Model Window start*/}
			<Modal
				isOpen={showModalPopupVisibility}
				srLabelledBy={'modal-id-001'}
				srDescribedBy={'modal-id-002'}>
				<ModalHeader
					titleId={'modal-id-001'}>
					Validation Error
				</ModalHeader>
				<ModalBody descriptionId="modal-id-002">
					<p>{modalMessage}</p>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup mode="static">
						<ButtonGroupItem slot="buttonGroupItem">
							<ButtonField>
								<button
									type="button"
									id="cancel"
									onClick={() => closeModal()}>
									Close
								</button>
							</ButtonField>
						</ButtonGroupItem>
					</ButtonGroup>
				</ModalFooter>
			</Modal>
			{/*Model Window end*/}
			<form>
				<div className="wk-grid-page-container">
					<div className="wk-grid custom-padding">
						<div className="wk-column-12 wk-column-12-xs">
							<span>Where would you like to receive your order receipt?</span>
						</div>
						<div className="wk-column-5 wk-column-12-xs">
							<InputField
								label="Email Address"
								labelFor="email"
								indicator="required"
							>
								<input
									type="text"
									id="email"
									name="email"
									placeholder=""
									value={user.email || ''}
									onChange={(e) => handleEmailChange(e.target.value)}
									disabled={disableEmailField}
								/>
								{errors && errors.email && <div className="text-danger">{errors.email}</div>}
							</InputField>
						</div>
						{checkoutProduct.product?.showLastName &&
							<div className="wk-column-5 wk-column-12-xs">
								<InputField
									label="Last Name"
									labelFor="ssoLastName"
									indicator="required"
								>
									<input
										type="text"
										id="ssoLastName"
										name="ssoLastName"
										placeholder=""
										value={ssoLastName || ''}
										onChange={(e) => handleSSOLastName(e.target.value)}
										disabled={disableEmailField}
									/>
									{errors && errors.ssoLastName && <div className="text-danger">{errors.ssoLastName}</div>}
								</InputField>
							</div>
						}
						{user.isNewUser && !checkoutProduct.product.isSSO && accountSteps !== 'membership-verification' &&
							<div className="wk-column-5 wk-column-12-xs">
								<SelectField
									label="Professional Designation"
									labelFor="professionaldesignation"
									indicator="required"

								>
									<select id="professionaldesignation" name="story1-1"
										value={user.professionalDesignation}
										onChange={(e) => handleProfessionalDesignationChange(e)}>
										<option key={'select-option'} value={0} defaultValue={0}>-- Please Select --</option>
										{
											designations.map((designation: KeyValuePair, index: number) => <option key={index} value={designation.key}>{designation.value}</option>)
										}
									</select>
									{errors && errors.professionalDesignation && <div className="text-danger">{errors.professionalDesignation}</div>}

								</SelectField>
							</div>
						}
					</div>
					{user.isNewUser && !checkoutProduct.product.isSSO && accountSteps !== 'membership-verification' &&
						<div className="wk-grid custom-padding">
							<div className="wk-column-5 wk-column-12-xs">
								<PasswordField
									labelFor="password"
									label="Password"
									type={passwordVisibility.password ? 'text' : 'password'}
									onModeChange={(event: any) => togglePasswordVisibility('password')}
									indicator="required"
								>
									<input
										type={passwordVisibility.password ? 'text' : 'password'}
										id="password"
										name="password"
										placeholder=""
										value={user.password || ''}
										onChange={(e) => handlePasswordChange(e.target.value)}
									/>
									{errors && errors.password && <div className="text-danger">{errors.password}</div>}
								</PasswordField>
							</div>
							<div className="wk-column-5 wk-column-12-xs">
								<PasswordField
									labelFor="ConfirmPassword"
									label="Re-type Password"
									type={passwordVisibility.confirmPassword ? 'text' : 'password'}
									indicator="required"
									onModeChange={(event: any) => togglePasswordVisibility('confirmPassword')}
								>
									<input
										type={passwordVisibility.confirmPassword ? 'text' : 'password'}
										id="ConfirmPassword"
										name="confirmpassword"
										placeholder=""
										value={user.confirmPassword || ''}
										onChange={(e) => handleConfirmPasswordChange(e.target.value)}
									/>
									{errors && errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
								</PasswordField>
							</div>
						</div>
					}

					<div className="wk-grid custom-padding">
						<div className="wk-column-5 wk-column-12-xs">
							<ButtonField>
								<button type="submit" style={{ marginRight: "5px" }} onClick={handleCustomerSubmit}>
									Continue
								</button>
							</ButtonField>
						</div>
						<div className="wk-column-12 wk-column-12-xs">
							<span className="required">Required Field(s)</span>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
export default AccountDetails;
