import React from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='footer'>
      <div className='Footer-section'>
        <div className="wk-grid-page-container docs-grid-container w-85vw">
          <div className="wk-grid pl-0">

            <div className="wk-column-12 wk-column-12-xs">
              <img src="https://cdn.wolterskluwer.io/wk/fundamentals/2.x.x/logo/assets/white-large.svg" className="p-bottom-4" alt="Wolters Kluwer white logo" />
              <div> <a target='_blank' href="/terms-of-use" className="wk-white-text footer-links">Terms of Use &nbsp;&nbsp;</a>
                <a target='_blank' href="/privacy" className="wk-white-text footer-links"> Privacy &amp; Cookies&nbsp;&nbsp;</a>
                <a target='_blank' href="/privacy/california" className="wk-white-text footer-links"> Your California Privacy Choices <img className="privacy-pill" src="/images/privacy-pill.png" alt='privacy-icon' /></a><br />
                <span className="wk-white-text margin-top-4 d-block">© {currentYear} Wolters Kluwer N.V. and/or its subsidiaries. All rights reserved.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;