import React, { useState } from 'react'

import DOMPurify from "isomorphic-dompurify";

const TermsAndConditions = (props: any) => {
  const pageUrl = props.config.assetsUrl + '/contents/terms.html';
  const [htmlBody, setHtmlBody] = useState('');
  
  // read the contents from source and sanitize it
  React.useEffect(() => {		
    fetch(pageUrl)
    .then(response => response.text())
    .then(text => {
      setHtmlBody(DOMPurify.sanitize(text));
  });
	}, []);// eslint-disable-line

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
  )
}


export default TermsAndConditions;